
















































































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";
import StatisticsBase from "../../../components/StatisticsBase.vue";
import { displayItemsWithName, displayItemsWithSku } from "@/utils/marketItemDisplay";

export default Vue.extend({
  components: { StatisticsBase },
  data() {
    return {
      userManager,
      self: this,
      tableOptions: {
        attrs: {
          "fixed-header": true,
        },
      },
      tableContainer: new DataContainer(),
      data: null,
      sortedProductGroups: [],
      warehouses: [],
      importManualCountBtnOptions: {
        attrs: {
          small: true,
          color: "primary",
          dark: true,
          class: "ml-2 mb-4",
        },
        content: {
          text: "Import số lượng thực trong kho",
          icon: "mdi-upload",
        },
        target: {
          tooltip: { content: { text: "Import" } },
          dialog: {
            handlers: {
              initialize() {
                this.options.content.buttons.save.states.enabled = new DataContainer(false);
              },
            },
            content: {
              toolbar: {
                title: "Import",
              },
              content: {
                type: "XForm",
                makeAttrs(attrs, dialog) {
                  attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                  attrs.xContext = dialog;
                  attrs.xData = new DataContainer({});
                  return attrs;
                },
                attrs: {
                  xOptions: {
                    content: {
                      sections: {
                        default: {
                          fields: {
                            file: {
                              type: "file",
                              attrs: {
                                label: "File",
                                required: true,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              buttons: {
                save: {
                  content: {
                    text: "Tải lên",
                  },
                  states: {},
                  on: {
                    async xClick({ self }) {
                      const dialog = self.context();
                      const form = dialog.contentContainer.value;
                      const data = form.getData();
                      self.loading.value = true;
                      const result = await coreApiClient.call("products", "importManualCount", {}, data);
                      self.loading.value = false;
                      if (result) {
                        dialog.hide();
                        location.reload();
                      }
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  async created() {},
  methods: {
    refreshMakePromisesFunc({ orderFilters }) {
      orderFilters = orderFilters.filter((f) => !["startDate", "endDate"].includes(f.id));
      orderFilters = orderFilters.concat([
        {
          key: "status",
          operator: "equal_to",
          value: "cbpu",
        },
      ]);

      const promises: any = {};
      function orderStatisticsFields(inSubAggregations = false, pcs = false) {
        let countObj: any = {
          total: {
            type: "terms",
            magicFields: {
              id: {
                value: "all",
              },
            },
            subAggregations: {
              count: {
                type: "count",
              },
            },
          },
        };
        if (inSubAggregations) {
          countObj = {
            count: {
              type: "count",
            },
          };
        }
        const value: any = {
          ...countObj,
          minCreatedTime: {
            type: "min",
            field: "createdTime",
          },
        };
        if (pcs) {
          value.pcs = {
            type: "sum",
            field: "productItems.count",
          };
        }
        return value;
      }
      const orderBaseAggregations = (pcs = false, productGroupField = "mainProductGroupIds") => {
        return {
          ...orderStatisticsFields(false, pcs),
          productGroups: {
            type: "terms",
            magicFields: {
              id: productGroupField,
            },
            subAggregations: orderStatisticsFields(true, pcs),
          },
          productGroupWithProductComboItems: {
            type: "terms",
            magicFields: {
              id: {
                funcName: "concat",
                fields: [
                  productGroupField,
                  {
                    value: "_",
                  },
                  {
                    funcName: "toString",
                    field: "productCombo._id",
                  },
                ],
              },
            },
            subAggregations: orderStatisticsFields(true, pcs),
          },
        };
      };
      promises.ordersWithMainProductGroupIds = coreApiClient.call("orders", "getStatistic", {
        payload: JSON.stringify({
          expandFields: ["mainProductGroupIds"],
          findRequest: {
            filters: orderFilters,
          },
          aggregations: orderBaseAggregations(false),
        }),
      });
      promises.ordersWithProductItems = coreApiClient.call("orders", "getStatistic", {
        payload: JSON.stringify({
          expandFields: ["productItems"],
          findRequest: {
            filters: orderFilters,
          },
          aggregations: orderBaseAggregations(true, "productItems.product.productGroupId"),
        }),
      });
      promises.productGroups = coreApiClient.call("productGroups", "findAll", {
        payload: JSON.stringify({
          limit: -1,
          includedProperties: ["_id", "sku", "name", "marketId", "market"],
        }),
      });
      promises.productCombos = coreApiClient.call("productCombos", "findAllWithoutMktPlatformFilters", {
        payload: JSON.stringify({
          limit: -1,
          includedProperties: ["_id", "sku", "name", "marketId", "market", "productItems"],
        }),
      });
      promises.products = coreApiClient.call("products", "findAll", {
        payload: JSON.stringify({
          limit: -1,
          includedProperties: [
            "_id",
            "sku",
            "name",
            "productGroupId",
            "manualCount",
            "countStatuses",
            "totalCountStatus",
          ],
        }),
      });
      promises.warehouses = coreApiClient.call("warehouses", "findAll", {
        limit: -1,
      });
      promises.orderCutSuggestions = coreApiClient.call("orders", "cutSuggestions");
      return promises;
    },
    refreshed(data) {
      this.data = data;
      const self = this;

      this.warehouses = displayItemsWithName(data.warehouses.items);
      const products = displayItemsWithSku(data.products.items);

      this.sortedProductGroups = displayItemsWithSku(data.productGroups.items).filter((pg) => {
        return self.cellValue("ordersWithMainProductGroupIds", "productGroups", pg._id, "count");
      });
      this.sortedProductGroups.forEach((pg) => {
        pg.productCombos = displayItemsWithName(data.productCombos.items)
          .filter((pc) => {
            return self.cellValue(
              "ordersWithMainProductGroupIds",
              "productGroupWithProductComboItems",
              `${pg._id}_${pc._id}`,
              "count"
            );
          })
          .sort((a, b) => {
            return (
              this.cellValue(
                "ordersWithProductItems",
                "productGroupWithProductComboItems",
                `${pg._id}_${a._id}`,
                "minCreatedTime"
              ) -
              this.cellValue(
                "ordersWithProductItems",
                "productGroupWithProductComboItems",
                `${pg._id}_${b._id}`,
                "minCreatedTime"
              )
            );
          });
        pg.products = products.filter((p) => p.productGroupId === pg._id);
        pg.warehouseCountStatuses = [];
        pg.warehouseToBeImported = [];
        for (const p of pg.products) {
          if (!p.countStatuses) {
            continue;
          }
          for (const countStatus of p.countStatuses) {
            const warehouse = self.warehouses.find((w) => w._id === countStatus.warehouseId);
            if (!warehouse) {
              continue;
            }
            let warehouseCountStatus = pg.warehouseCountStatuses.find(
              (ws) => ws.warehouse._id === countStatus.warehouseId
            );
            if (!warehouseCountStatus) {
              warehouseCountStatus = {
                warehouse,
                count: 0,
                expectedImportCount: 0,
              };
              pg.warehouseCountStatuses.push(warehouseCountStatus);
            }
            warehouseCountStatus.count += countStatus.count;
            warehouseCountStatus.expectedImportCount += countStatus.expectedImportCount;
          }
        }
      });
      this.sortedProductGroups = this.sortedProductGroups.sort((a, b) => {
        return (
          this.cellValue("ordersWithProductItems", "productGroups", a._id, "minCreatedTime", 0) -
          this.cellValue("ordersWithProductItems", "productGroups", b._id, "minCreatedTime", 0)
        );
      });
      this.contentResized();
    },
    contentResized() {
      this.$nextTick(() => {
        const self = this;
        const timer = setInterval(() => {
          const table = self.tableContainer.value;
          if (!table) {
            return;
          }
          table.recalculateHeight();
          clearInterval(timer);
        }, 200);
      });
    },
    cellValue(source, mainKey, key, subKey, defaultValue = "") {
      const sourceValue = this.data[source];
      if (!sourceValue) {
        return defaultValue;
      }
      if (!mainKey) {
        return sourceValue;
      }
      const mainValue = sourceValue[mainKey];
      if (!mainValue) {
        return defaultValue;
      }
      if (!key) {
        return mainValue;
      }
      const value = mainValue[key];
      if (!value) {
        return defaultValue;
      }
      return (value && value.info && value.info[subKey]) || defaultValue;
    },
    totalCount(warehouseId?) {
      return this.sortedProductGroups.reduce(
        (total, { warehouseCountStatuses }) =>
          total +
          warehouseCountStatuses
            .filter((wcs) => !wcs.warehouse.disabled && (!warehouseId || wcs.warehouse._id === warehouseId))
            .reduce((subTotal, { count }) => {
              return subTotal + count;
            }, 0),
        0
      );
    },
    totalExpectedImportCount(warehouseId?) {
      return this.sortedProductGroups.reduce(
        (total, { warehouseCountStatuses }) =>
          total +
          warehouseCountStatuses
            .filter((wcs) => !wcs.warehouse.disabled && (!warehouseId || wcs.warehouse._id === warehouseId))
            .reduce((subTotal, { expectedImportCount }) => {
              return subTotal + expectedImportCount;
            }, 0),
        0
      );
    },
    orderCutSuggestionForProductCombo(productGroupId, productComboId) {
      const productGroup = this.data.orderCutSuggestions.find((pg) => pg._id === productGroupId);
      if (!productGroup) {
        return 0;
      }
      const productCombo = productGroup.productCombos.find((pc) => pc._id === productComboId);
      if (!productCombo) {
        return 0;
      }
      return productCombo.count;
    },
  },
});
