var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.userManager.checkRole(['kt', 'vd', 'vdl']))?_c('div',[_vm._v("Bạn không có quyền truy cập trang này")]):_c('div',[_c('statistics-base',{attrs:{"label":"Gợi ý cut đơn","refreshMakePromisesFunc":_vm.refreshMakePromisesFunc,"timeKey":"createdTime","disableTimeFilter":true},on:{"refreshed":_vm.refreshed,"contentResized":_vm.contentResized},scopedSlots:_vm._u([{key:"beforeTimeFilter",fn:function(){return [_c('x-btn',{attrs:{"x-options":_vm.importManualCountBtnOptions,"x-context":_vm.self}})]},proxy:true},{key:"default",fn:function(){return [(_vm.data)?_c('x-simple-table',{staticClass:"fixedcol--enabled",attrs:{"x-options":_vm.tableOptions,"x-container":_vm.tableContainer,"x-context":_vm.self}},[_c('thead',[_c('tr',[_c('th',{staticClass:"fixedcol fixedcol1"},[_vm._v("STT")]),_c('th',{staticClass:"fixedcol fixedcol2"},[_vm._v("Nhóm sản phẩm")]),_c('th',[_vm._v("Số lượng pcs cần gửi đơn đi")]),_c('th',[_vm._v("Số lượng pcs trong kho thực tế trên PM")]),_c('th',[_vm._v("Số lượng pcs hàng đang đi đường trên PM")]),_c('th',[_vm._v("Số lượng pcs trong kho thực tế")]),_c('th',[_vm._v("Combo bán")]),_c('th',[_vm._v("Date order muộn nhất chưa gửi đi")]),_c('th',[_vm._v("Số lượng pcs của từng combo")]),_c('th',[_vm._v("Số lượng đơn chưa gửi đi")]),_c('th',[_vm._v("Gợi ý cut đơn")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"fixedcol fixedcol1"}),_c('td',{staticClass:"fixedcol fixedcol2"},[_vm._v("Tổng")]),_c('td',[_vm._v(" "+_vm._s(_vm.cellValue("ordersWithProductItems", "pcs"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number2")(_vm.totalCount()))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("number2")(_vm.totalExpectedImportCount()))+" pcs")]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')]),_vm._l((_vm.sortedProductGroups),function(ref,i){
var productGroupId = ref._id;
var productGroupSku = ref.sku;
var products = ref.products;
var productCombos = ref.productCombos;
var warehouseCountStatuses = ref.warehouseCountStatuses;
return _vm._l((productCombos),function(ref,index){
var productComboId = ref._id;
var productComboName = ref.name;
return _c('tr',{key:("pg_" + productGroupId + "_pc_" + productComboId)},[(index == 0)?[_c('td',{staticClass:"fixedcol fixedcol1",attrs:{"rowspan":productCombos.length}},[_vm._v(" "+_vm._s(+i + 1)+" ")]),_c('td',{staticClass:"fixedcol fixedcol2",attrs:{"rowspan":productCombos.length}},[_vm._v(" "+_vm._s(productGroupSku)+" ")]),_c('td',{attrs:{"rowspan":productCombos.length}},[_vm._v(" "+_vm._s(_vm._f("number2")(_vm.cellValue("ordersWithProductItems", "productGroups", productGroupId, "pcs")))+" ")]),_c('td',{attrs:{"rowspan":productCombos.length}},_vm._l((warehouseCountStatuses),function(ref){
var warehouse = ref.warehouse;
var count = ref.count;
return _c('div',{key:("pg_" + productGroupId + "_wh_" + (warehouse._id))},[_vm._v(" "+_vm._s(warehouse.name)+": "+_vm._s(_vm._f("number2")(count))+" pcs ")])}),0),_c('td',{attrs:{"rowspan":productCombos.length}},_vm._l((warehouseCountStatuses),function(ref){
var warehouse = ref.warehouse;
var expectedImportCount = ref.expectedImportCount;
return _c('div',{key:("pg_" + productGroupId + "_wh_" + (warehouse._id))},[_vm._v(" "+_vm._s(warehouse.name)+": "+_vm._s(_vm._f("number2")(expectedImportCount))+" pcs ")])}),0),_c('td',{attrs:{"rowspan":productCombos.length}},_vm._l((products),function(ref){
var productId = ref._id;
var sku = ref.sku;
var manualCount = ref.manualCount;
var totalCountStatus = ref.totalCountStatus;
return _c('div',{key:("product_" + productId)},[_vm._v(" "+_vm._s(sku)+": "+_vm._s(manualCount || totalCountStatus.count || 0)+" ")])}),0)]:_vm._e(),_c('td',[_vm._v(_vm._s(productComboName))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateTime")(_vm.cellValue( "ordersWithMainProductGroupIds", "productGroupWithProductComboItems", (productGroupId + "_" + productComboId), "minCreatedTime" )))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number2")(_vm.cellValue( "ordersWithProductItems", "productGroupWithProductComboItems", (productGroupId + "_" + productComboId), "pcs" )))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number2")(_vm.cellValue( "ordersWithMainProductGroupIds", "productGroupWithProductComboItems", (productGroupId + "_" + productComboId), "count" )))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number2")(_vm.orderCutSuggestionForProductCombo(productGroupId, productComboId)))+" ")])],2)})})],2)]):_vm._e()]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }